import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { appConfig } from './app-config'
import { useCurrentUser } from '@base-app/library'
export function useServiceWorkerRegistration(): ServiceWorkerRegistration | null {
    const { data } = useSuspenseQuery({
        queryKey: ['serviceWorker', 'registration'],
        queryFn: async () => (await navigator.serviceWorker.getRegistration()) ?? null,
    })

    return data
}

export function usePushNotificationSubscription() {
    const registration = useServiceWorkerRegistration()

    const { data } = useSuspenseQuery({
        queryKey: ['serviceWorker', 'pushSubscription', registration?.scope],
        queryFn: () => registration?.pushManager?.getSubscription() ?? null,
    })

    return data
}

type Registration = { id: number; deviceType: 'Unknown' | 'Android' | 'Ios' | 'Desktop'; registeredAt: string }

type UseNotificationStatusReturn = {
    isBusy: boolean
    status: 'subscribed' | 'default' | 'denied' | 'granted' | 'error' | undefined
    subscription: PushSubscription | null
    installations: Registration[] | undefined
    subscribe: () => void
    unsubscribe: () => void
    unsubscribeAll: () => void
}
export function useNotificationStatus(): UseNotificationStatusReturn {
    const registration = useServiceWorkerRegistration()
    const subscription = usePushNotificationSubscription()
    const user = useCurrentUser()
    const queryClient = useQueryClient()

    const { data: status } = useQuery({
        queryKey: ['serviceWorker', 'notificationPermission'],
        queryFn: () => {
            try {
                return Notification.permission
            } catch (_) {
                return 'error'
            }
        },
        refetchInterval: 10_000,
    })

    const httpClient = useMemo(() => {
        return user.createHttpClient(appConfig.API_BASE_NOTIFICATIONS)
    }, [user])

    const installationsQuery = useQuery({
        queryKey: ['notifications', 'installations'],
        queryFn: ({ signal }) => httpClient.get<Registration[]>({ signal }, 'Registrations'),
    })

    const subscribe = useMutation({
        mutationFn: async () => {
            const abortController = new AbortController()
            const permission = await Notification.requestPermission()
            if (permission !== 'granted') {
                return false
            }

            const newSubscription = await registration?.pushManager?.subscribe({
                userVisibleOnly: true,
                applicationServerKey: appConfig.VAPID_PUBLIC_KEY,
            })
            if (newSubscription) {
                const keys = newSubscription.toJSON().keys ?? {}

                await httpClient
                    .post({ signal: abortController.signal }, 'RegisterDevice', {
                        appClientId: appConfig.installationId,
                        endpoint: newSubscription.endpoint,
                        keys: {
                            auth: keys.auth,
                            p256dh: keys.p256dh,
                        },
                        deviceType: 'Desktop',
                    })
                    .catch(async (reason) => {
                        await newSubscription.unsubscribe()
                        throw reason
                    })
            }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['serviceWorker'],
                exact: false,
                type: 'all',
            })
            return installationsQuery.refetch()
        },
    })

    const unsubscribe = useMutation({
        mutationFn: async () => {
            await subscription?.unsubscribe()
            await httpClient.post(new AbortController(), 'UnregisterDevice', {
                appClientId: appConfig.installationId,
            })
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['serviceWorker'],
                exact: false,
                type: 'all',
            })
            return installationsQuery.refetch()
        },
    })

    const unsubscribeAll = useMutation({
        mutationFn: async () => {
            await subscription?.unsubscribe()
            await httpClient.post(new AbortController(), 'UnregisterAllDevices')
        },
        onSettled: async () => {
            return installationsQuery.refetch()
        },
    })

    return {
        isBusy: subscribe.isPending || unsubscribe.isPending || unsubscribeAll.isPending,
        status: subscription ? 'subscribed' : status,
        subscription,
        subscribe: subscribe.mutate,
        unsubscribe: unsubscribe.mutate,
        unsubscribeAll: unsubscribeAll.mutate,
        installations: installationsQuery.data,
    } satisfies UseNotificationStatusReturn
}
