import zod from 'zod'

const config = JSON.parse(document.getElementById('app-config')?.innerHTML ?? '{}')
const installationId = localStorage.getItem('INSTALLATION_ID') ?? crypto.randomUUID()

localStorage.setItem('INSTALLATION_ID', installationId)

const schema = zod.object({
    AUTH_AUTHORITY: zod.string(),
    AUTH_CLIENT_ID: zod.string(),
    AUTH_REDIRECT_URL: zod.string().url(),
    AUTH_SCOPE: zod.string(),
    API_BASE_TOKEN_SERVICE: zod.string().url(),
    API_BASE_PROXY_SERVICE: zod.string().url(),
    API_BASE_NOTIFICATIONS: zod.string().url(),
    API_BASE_WEBAUTHN: zod.string().url(),
    VAPID_PUBLIC_KEY: zod.string().min(10),
    plugins: zod.array(zod.string().url()).default([]),
    installationId: zod.string().default(installationId),
})

export const appConfig = schema.parse(config)
