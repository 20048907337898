import { useCurrentUser } from '@base-app/library'
import { useSuspenseQuery } from '@tanstack/react-query'
import { DebugInfo } from '../debug'
import { useNotificationStatus, useServiceWorkerRegistration } from '../notifications'

export function Home() {
    const user = useCurrentUser()
    const ip = useSuspenseQuery({
        queryFn: async ({ signal }) => {
            return (await user?.tokenClient.get<string>({ signal }, 'ip')) ?? 'no-ip'
        },
        queryKey: ['ip'],
    })
    const notificationStatus = useNotificationStatus()
    const registration = useServiceWorkerRegistration()

    return (
        <div>
            <p>
                Hello {user?.profile?.firstName} (authenticated by {user?.profile?.authenticationSource})
            </p>
            <p>No place like {ip.data}</p>
            <h2>Notifications</h2>
            <p>{notificationStatus.status}</p>
            <h2>Send a test notification</h2>
            <button
                type="button"
                disabled={registration === null}
                onClick={async () => {
                    // iOS does not show the notification if we haven't also kinda just asked for permission. Odd...
                    await Notification.requestPermission()
                    return registration?.showNotification('This is a local notification', {
                        badge: '1',
                        body: 'You clicked the button',
                        data: {
                            source: 'local',
                            id: crypto.randomUUID(),
                        },
                    })
                }}
            >
                Send local test notification
            </button>
            <h2>Identity</h2>
            {notificationStatus.status === 'denied' && (
                <p>
                    You have disallowed notifications in your browser. To use push notifications, this site needs to be
                    able to notify you of important updates.
                </p>
            )}
            <button
                type="button"
                onClick={() => notificationStatus.subscribe()}
                disabled={
                    notificationStatus.status === 'subscribed' ||
                    notificationStatus.status === 'denied' ||
                    notificationStatus.isBusy
                }
            >
                Subscribe
            </button>
            <button
                type="button"
                onClick={() => notificationStatus.unsubscribe()}
                // disabled={notificationStatus.status !== 'subscribed' || notificationStatus.isBusy}
            >
                Unsubscribe
            </button>
            <button
                type="button"
                onClick={() => notificationStatus.unsubscribeAll()}
                disabled={notificationStatus.status !== 'subscribed' || notificationStatus.isBusy}
            >
                Unsubscribe all browsers
            </button>
            <h3>Installations across browsers</h3>
            <ul>
                {notificationStatus.installations?.map((item) => (
                    <li key={item.id}>
                        <strong>{item.deviceType}: </strong>
                        <span>{item.registeredAt}</span>
                    </li>
                ))}
            </ul>
            <hr />
            <button
                type="button"
                onClick={user.onLogout}
            >
                Log me off
            </button>

            <hr />
            <DebugInfo />
        </div>
    )
}
